import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.config.productionTip = false

import Maska from 'maska'
Vue.use(Maska)

import "@/assets/global.css"
import axios from 'axios'
Vue.prototype.$http = axios; 



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret,faCamera, faWarehouse,faQrcode,faQuestion,faQuestionCircle ,faSquare} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret,faCamera, faWarehouse,faQrcode,faQuestion,faQuestionCircle ,faSquare )

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)




const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
