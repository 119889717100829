<template>
  <v-app  translate="no" class="notranslate">
    <v-app-bar
      app
      style="
        background-image: url('img/back_appbar.png');
        background-size: 100% 100%;
        height:180px;
      "
      v-if="isLoggedIn"
    >
      <v-container v-if="page_title!='main'">
        <v-row no-gutters align="center" justify="center">
          <v-col cols="2" sm="2" md="2">
            <v-icon v-if="page_title!='Головна'" color="white" @click="go_back()"
              >mdi-keyboard-backspace</v-icon
            >

           
          </v-col>
<v-col align="left" cols="8" sm="8" md="8">
           <span 
              ><font color="white" style="font-size:22px;"><center><b>{{ page_title }}</b></center></font></span
            >
          </v-col>
          <v-col align="right" cols="2" sm="2" md="2">
            <v-img  v-if="page_title!='Головна'"  src="img/menu_ico.png" style="width:20px;height:20px;"  @click="$router.push({name:'Main'})"></v-img>
            <!-- <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
              color="white" 
            ></v-app-bar-nav-icon> -->
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="page_title=='main'">
        <v-row><v-col cols="4"><span class="arirnd" style="color:white;font-size:45px;"><br><br>iTeam </span></v-col><v-col cols="8"></v-col></v-row>
      </v-container>


      <v-card class="rounded-lg" 
      
      v-if="['LPR_STAT','News_pg','News_sel','Main1','LPR_STAT','News','Rate','Phototask','Tasks','Testtask','C_info','Withdraw_sel','Withdraw_silpo','Withdraw_rozetka','Withdraw_mob','Withdraw_fishka','Withdraw_eva','Main','TPQ','Info_sel',
      'Info_Brands_sel',
      'Info_Brands_Davidoff',
      'Info_Brands_Davidoff_SL',
      'Info_Brands_Davidoff_PL',
      'Info_Brands_Davidoff_reach',
      'Info_Brands_West_KS',
      'Info_Brands_West_UP',
      'Info_Brands_West_XL',
      'Info_Brands_West',
      'Info_Brands_PS_BIG',
      'Info_Brands_PS_COMPACT',
      'Info_Brands_PS_KS',
      'Info_Brands_PS',
      'Info_Brands_Jade',
      'Info_Brands_Jade_COMPACT',
      'Info_Brands_Jade_SSL'].includes(this.$route.name)"
      elevation="10" :class="this.$route.name=='Main'||this.$route.name=='Main1'?'card':'card'">
          
      <v-card-text 
          :class="this.$route.name=='Info_Brands_Davidoff'||this.$route.name=='Info_Brands_Davidoff_SL'||this.$route.name=='Info_Brands_Davidoff_PL'||this.$route.name=='Info_Brands_Davidoff_reach'||this.$route.name=='Info_Brands_West_KS'||this.$route.name=='Info_Brands_West_UP'||this.$route.name==      'Info_Brands_West_XL'||this.$route.name=='Info_Brands_West'||this.$route.name=='Info_Brands_PS_BIG'||this.$route.name=='Info_Brands_PS_COMPACT'||this.$route.name=='Info_Brands_PS_KS'||this.$route.name=='Info_Brands_PS'||this.$route.name=='Info_Brands_Jade'||this.$route.name=='Info_Brands_Jade_COMPACT'||this.$route.name=='Info_Brands_Jade_SSL'?'cardstyle2':'cardstyle1'"
          >

            <center>
      <!-- <v-img v-if="this.$route.name=='LPR_STAT'" src="img/ico_top/cd.png" class="icotop" style="" contain></v-img> -->



      <v-img v-if="this.$route.name=='Phototask'" src="img/ico_top/photo.png" class="icotop" style="" contain></v-img>
      
      <v-img v-if="this.$route.name=='Tasks'" src="img/ico_top/test.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Testtask'" src="img/ico_top/test.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='C_info'" src="img/ico_top/info.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_sel'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_silpo'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_rozetka'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_mob'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_fishka'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Withdraw_eva'" src="img/ico_top/withdraw.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Main'" src="img/ico_top/xxxhdpi_wo2.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Main1'" src="img/ico_top/xxxhdpi_wo2.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='TPQ'" src="img/ico_top/news.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='LPR_STAT'" src="img/ico_top/stat.png" class="icotop" style="" contain></v-img>
      
      <v-img v-if="this.$route.name=='News_sel'" src="img/ico_top/news.png" class="icotop" style="" contain></v-img> 
      <v-img v-if="this.$route.name=='News_pg'" src="img/ico_top/news.png" class="icotop" style="" contain></v-img> 
      <v-img v-if="this.$route.name=='News'" src="img/ico_top/news.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Rate'" src="img/ico_top/rate.png" class="icotop" style="" contain></v-img>




      <v-img v-if="this.$route.name=='Info_sel'" src="img/ico_top/info.png" class="icotop" style="" contain></v-img>
      <v-img v-if="this.$route.name=='Info_Brands_sel'" src="img/ico_top/info.png" class="icotop" style="" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Davidoff'" src="img/ico_top/davidoff_black.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Davidoff_SL'" src="img/ico_top/davidoff_black.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Davidoff_PL'" src="img/ico_top/davidoff_black.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Davidoff_reach'" src="img/ico_top/davidoff_black.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_West'" src="img/ico_top/west_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_West_KS'" src="img/ico_top/west_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_West_UP'" src="img/ico_top/west_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_West_XL'" src="img/ico_top/west_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_PS'" src="img/ico_top/ps_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_PS_BIG'" src="img/ico_top/ps_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_PS_KS'" src="img/ico_top/ps_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_PS_COMPACT'" src="img/ico_top/ps_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Jade'" src="img/ico_top/jade_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Jade_SSL'" src="img/ico_top/jade_ico_top.png" class="icotop" style="width:140px" contain></v-img>
       <v-img v-if="this.$route.name=='Info_Brands_Jade_COMPACT'" src="img/ico_top/jade_ico_top.png" class="icotop" style="width:140px" contain></v-img> 
        </center>
        
         </v-card-text>

      
        
      </v-card>
      


    </v-app-bar>
    <!-- <v-navigation-drawer v-model="drawer" right fixed> -->
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img :src="avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            >{{ fname }} {{ lname }}

            <v-subheader>{{ points }}</v-subheader>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-divider></v-divider>
      <v-list dense> -->
        <!-- <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="
            $router.push({
              name: item.name,
              params: { routeparam: item.paramlink },
            })
          "
        >
          <v-list-item-icon>
            <v-img contain :src="item.icon"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>{{ item.title }}</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          v-if=isLoggedIn
          @click=logout
        >
          <v-list-item-icon color="orange">
             <v-img contain src="img/m_ico8.png"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>Вихід</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if=isLoggedIn
          @click.native="$router.push({name:'Main'})"
        >
          <v-list-item-icon color="orange">
             <v-img contain src="img/m_ico8.png"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <label>Головна</label>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
     

    </v-navigation-drawer> -->

    <v-overlay :value="isLoading">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="cvalue"
        color="#ee6923"
      >
        {{ cvalue }}
      </v-progress-circular>
      <br />
    </v-overlay>

    <!-- <v-main style="z-index:500"> -->
    <v-main translate="no" class="notranslate">
      <router-view  @pushe="pushe"></router-view>
    </v-main>


    <v-dialog
        v-model="push_dialog"
        width="300" style="z-index:55000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">{{push_header}}</v-card-title>
          <v-card-text>
             <v-row> <v-col v-html="push_text">
             
                </v-col>
             </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              @click="push_dialog = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>







<script>
import axios from "axios";

import { API_LOCATION } from "../config";
export default {
  name: "App",

  components: {},
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
    
  },

  data: () => ({
    //iconTeam: require("./assets/LogoITG.svg"),
    drawer: false,

    push_dialog:false,
    push_header:'',
    push_text:'',
    uploadPercentage: 0,
    cvalue: 0,
    refCount: 0,
    opDesc: [],
    isLoading: false,
    //
  }),

watch:{
  $route (){
      this.push_check();
  }
} ,


  created() {



   // this.intervalid1 = setInterval(this.push_check, 60000);






    console.log(API_LOCATION);


    // axios
    //   .post(
    //     API_LOCATION,
    //     {
          
    //       action: "version_check"
          
    //     },
    //     { headers: { "content-type": "application/x-www-form-urlencoded" } }
    //   )
    //   .then((resp) => {
    //           console.log(resp.data);  
    //           var version=190
    //           if(resp.data!==version)
    //           {
    //             location.reload();  
    //           }
        
    //   });


    console.log(this.$store);
    axios.interceptors.request.use(
      (config) => {
        this.setLoading(true);

        return config;
      },
      (error) => {
        this.setLoading(false);
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          if (
            error.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest
          ) {
            this.$store.dispatch("logout");
          }
          console.log(error);
          return error;
        });
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.setLoading(false);
        return response;
      },
      (error) => {
        this.setLoading(false);
        //eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => { 
          console.log(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
      }
    );
    axios.interceptors.response.use(undefined, function (err) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        console.log(err.status);
        throw err;
      });
    });

    this.$store.dispatch("page_title_set", "main");
    if (this.isLoggedIn) {
      this.$store.dispatch("profileset")
      .catch((err) => console.log(err));
    }
  },

  

    methods: {

    pushe(data){
      this.push_dialog=true;
      this.push_header=data.header;
      this.push_text=data.text;
      console.log('push emit:'+data);
    },


    push_check(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "push"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        console.log(resp.data);
              if(resp.data!=false)         {
                this.pushe({header:resp.data.header,text:resp.data.message});              }
        
      });
    },


      go_back(){
          //if(this.$route.name=='Phototask'||this.$route.name=='Testtask'){this.$router.push({ name: 'Main' })} else { this.$router.go(-1) this.$router.}
           this.$router.go(-1)
      },

    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;

        this.isLoading = this.refCount > 0;
      }
      this.cvalue = Math.round(100 / this.refCount);
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  
    
    
}

</script>
<style>
.cardstyle1{
  background-color:#fdaa47;color:white;text-align:center; padding:6px !important;
}


.cardstyle2{
  background-color:#ffffff;color:white;text-align:center; padding:6px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-width: 3px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-width: 3px;
  border-top-color: black;
  border-top-style: solid;
}

.card{
   position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;
}
.card-ny{
  position:fixed !important;top:90px !important;left:0 !important;right:0 !important; margin:auto !important;z-index:1000 !important;width:80% !important;border-radius: 18px !important;
}

</style>